//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { serverUrl } from '@/mixins/config.js'
export default {
  data() {
    return {}
  },
  methods: {
    goapp() {
      const url =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww8415e9f193398552&redirect_uri=http%3A%2F%2F' +
        serverUrl +
        '%2F&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      window.location.href = url
    },
  },
}
